import { getResourceSuccessMessage } from '../common'
import { DeleteButton } from '../common/buttons'

export const DeleteOpsUserButton = ({ condition }) => (
  <DeleteButton
    condition={condition}
    confirmTitle="resources.ops-users.actions.delete.confirmTitle"
    successMessage={getResourceSuccessMessage('ops-users', 'actions', 'delete')}
  />
)
