import config from './config'
import VehicleUnavailabilityFormLayout, { transformValues } from './form'
import { getResourceSuccessMessage } from '../common'
import { DeleteButton } from '../common/buttons'
import { AdvancedCreateButton } from '../common/create'

export const DeleteVehicleUnavailabilityButton = () => (
  <DeleteButton
    confirmTitle="resources.maintenances.actions.delete.confirmTitle"
    successMessage={getResourceSuccessMessage('maintenances', 'actions', 'delete')}
  />
)

export const CreateVehicleUnavailabilityButton = ({ defaultValues, ...props }) => (
  <AdvancedCreateButton
    defaultValues={defaultValues}
    isFloatingOnSmallScreen={false}
    label="resources.maintenances.actions.create.name"
    resource={config.name}
    transform={transformValues}
  >
    <VehicleUnavailabilityFormLayout {...props} />
  </AdvancedCreateButton>
)
