import { LinearProgress } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useEffect } from 'react'
import {
  AutocompleteInput,
  Labeled,
  ReferenceInput,
  required,
  SelectInput,
  useGetList,
  useInput,
  useSaveContext,
} from 'react-admin'

import { SYSTEM_PERMISSION_READ } from '../config/permissions'
import { useResourcePermissions } from '../domain/permissions'
import { useHelperText } from '../resources/common/forms'

const useStyles = makeStyles({
  linearProgress: {
    margin: (props) => (props.isFormTypeFilter ? 6 : 16),
    width: (props) => (props.isFormTypeFilter ? 227 : 256),
  },
})

const AdvancedReferenceInput = ({
  emptyText,
  emptyValue,
  excludedIds = [],
  filter,
  format,
  parse,
  perPage = 10,
  readOnly,
  reference,
  sort,
  validate,
  ...props
}) => {
  const [hasReadForReference] = useResourcePermissions(reference, SYSTEM_PERMISSION_READ)
  const { data, total, isLoading } = useGetList(
    reference,
    {
      filter: { excluded_ids: excludedIds, ...filter },
      pagination: { page: 1, perPage },
      sort,
    },
    { enabled: hasReadForReference },
  )

  const helperText = useHelperText(props.source)

  const {
    field: { value, onChange },
  } = useInput(props)

  const isFormTypeFilter = !useSaveContext()
  const isFieldRequired = validate === required() || (Array.isArray(validate) && validate.includes(required()))
  const shouldAutoSelectSingleOption = (isFieldRequired || isFormTypeFilter) && total === 1

  const classes = useStyles({ isFormTypeFilter })

  const commonParentInputProps = { ...props }
  const commonChildInputProps = {
    emptyValue,
    format,
    helperText: isFormTypeFilter ? false : props.helperText ?? helperText,
    parse,
    readOnly,
    validate,
    label: props.label,
  }

  useEffect(() => {
    const valueInAvailableOptions = data?.some((item) => item.id === value)
    const shouldClearValue = (total === 0 && value !== (emptyValue ?? '')) || total <= perPage

    if (!isLoading && !valueInAvailableOptions) {
      if (shouldAutoSelectSingleOption) {
        setTimeout(() => onChange(data[0].id), 50) // tiny delay to run after interactions and prevent bugs where the value is not set
      } else if (shouldClearValue) {
        onChange('')
      }
    }
  }, [JSON.stringify(data), value, onChange, isLoading, total]) // eslint-disable-line react-hooks/exhaustive-deps

  if (isLoading) {
    return (
      <Labeled {...props} className={classes.linearProgress}>
        <LinearProgress />
      </Labeled>
    )
  }

  return (
    <ReferenceInput
      {...commonParentInputProps}
      enableGetChoices={({ search_designation }) => search_designation && search_designation?.length >= 1}
      filter={{ excluded_ids: excludedIds, ...filter }}
      perPage={perPage}
      reference={reference}
      sort={sort}
    >
      {total > perPage || total === 0 ? (
        <AutocompleteInput
          size="small"
          margin="dense"
          {...commonChildInputProps}
          emptyText={emptyText}
          noOptionsText="mymove.noOption"
          filterToQuery={(searchText) => ({ search_designation: searchText })}
        />
      ) : (
        <SelectInput
          {...commonParentInputProps}
          {...commonChildInputProps}
          defaultValue={shouldAutoSelectSingleOption && data ? data[0].id : props.defaultValue}
          emptyText={isFormTypeFilter ? 'mymove.all' : emptyText}
          readOnly={readOnly || shouldAutoSelectSingleOption}
        />
      )}
    </ReferenceInput>
  )
}

export default AdvancedReferenceInput
