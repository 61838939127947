import EventNoteIcon from '@mui/icons-material/EventNote'
import ListIcon from '@mui/icons-material/List'
import { Box, ToggleButton, ToggleButtonGroup, Tooltip, Typography } from '@mui/material'
import { useTranslate } from 'ra-core'

export const viewModeTimeline = 'timeline'
export const viewModeList = 'list'

const ViewModeToggle = ({ disabled, mode, onChange }) => {
  const translate = useTranslate()
  const tooltip = translate('mymove.scheduler.disabledTooltip')
  return (
    <Box flex={1} display="flex" justifyContent="flex-end">
      <Tooltip placement="left" title={disabled ? <Typography sx={{ fontSize: '12px' }}>{tooltip}</Typography> : false}>
        <ToggleButtonGroup
          disabled={disabled}
          value={mode}
          exclusive
          onChange={(e, value) => {
            value && onChange(value)
          }}
          size="small"
        >
          <ToggleButton value={viewModeList}>
            <ListIcon />
          </ToggleButton>
          <ToggleButton value={viewModeTimeline}>
            <EventNoteIcon />
          </ToggleButton>
        </ToggleButtonGroup>
      </Tooltip>
    </Box>
  )
}

export default ViewModeToggle
