import { CreateDialog } from '@react-admin/ra-form-layout'
import { pick } from 'lodash'
import { useTranslate } from 'ra-core'
import { useEffect, useMemo, useRef, useState } from 'react'
import {
  ArrayInput,
  CREATE,
  FormDataConsumer,
  RadioButtonGroupInput,
  required,
  SimpleFormIterator,
  UPDATE,
  useGetOne,
  useListContext,
  useRecordContext,
} from 'react-admin'
import { useFormContext, useWatch } from 'react-hook-form'

import { useGetBookingsJustifications } from './hooks'
import AdvancedBooleanInput from '../../components/AdvancedBooleanInput'
import AdvancedDateTimeInput from '../../components/AdvancedDateTimeInput'
import BasicFormToolbar from '../../components/BasicFormToolbar'
import BookingJustificationInput from '../../components/BookingJustificationInput'
import {
  ACCOUNT_BILLING_SETTINGS_SOURCES,
  ACCOUNT_BILLING_TYPES_SOURCE,
  ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE,
} from '../../config/accounts'
import {
  BOOKING_BILLING_SETTINGS_SOURCE,
  BOOKING_BILLING_TYPE_FREE,
  BOOKING_BILLING_TYPE_PAID,
  BOOKING_BILLING_TYPE_SOURCE,
  BOOKING_BILLING_TYPES,
  BOOKING_ERA_UPCOMING,
  BOOKING_MAKE_BOOKING_FREE_SOURCE,
} from '../../config/bookings'
import { SYSTEM_PERMISSION_READ } from '../../config/permissions'
import { COMMON_INPUT_WIDTH, useCommonStyles } from '../../config/theme'
import { BILLING_TYPES_OVERRIDE_SOURCE } from '../../config/users'
import { isBookingPaid } from '../../domain/bookings'
import { useInputDenyPermission, useResourcePermissions } from '../../domain/permissions'
import { hasOnlyBillingType, isDisabled } from '../../utils'
import { getDateWithThirtyMinutesOffset, getRoundedNow, validateEndDateAfterStartDate } from '../../utils/dates'
import { useSmallScreen } from '../../utils/theme'
import { useGetCurrentAccount } from '../accounts/hooks'
import { FormDivider } from '../common'
import { useCreateResource } from '../common/create'
import { useEditResource } from '../common/edit'
import { AdvancedSimpleForm, SimpleFormIteratorAddButton } from '../common/forms'
import hubsConfig from '../hubs/config'
import HubReferenceInput from '../hubs/input'
import organisationsConfig from '../organisations/config'
import OrganisationReferenceInput from '../organisations/input'
import usersConfig from '../users/config'
import UserReferenceInput from '../users/input'
import vehiclesConfig from '../vehicles/config'
import VehicleReferenceInput from '../vehicles/input'
import vouchersConfig from '../vouchers/config'
import VoucherReferenceInput from '../vouchers/input'

const getBillingTypeChoices = (billingTypes) => {
  const orderedBillingTypes = Object.keys(BOOKING_BILLING_TYPES).filter((type) => billingTypes.includes(type))
  const filteredBillingTypes = pick(BOOKING_BILLING_TYPES, orderedBillingTypes)
  return Object.entries(filteredBillingTypes).map(([id, name]) => ({ id, name }))
}

export const transformValues = ({
  additional_driver_user_ids,
  billing_settings,
  billing_type,
  has_voucher,
  make_booking_free,
  ...restValues
}) =>
  restValues
    ? {
        ...restValues,
        additional_driver_user_ids: additional_driver_user_ids
          ? additional_driver_user_ids.map(({ user_id }) => user_id)
          : [],
        [BOOKING_BILLING_TYPE_SOURCE]: make_booking_free ? BOOKING_BILLING_TYPE_FREE : billing_type,
      }
    : restValues

const BillingSettingsInjector = ({ accountBillingSettings, formType, record }) => {
  const { setValue } = useFormContext()
  const [isFormReady, setIsFormReady] = useState(false)
  const [billingSettings, billingType, userId] = useWatch({
    name: [BOOKING_BILLING_SETTINGS_SOURCE, BOOKING_BILLING_TYPE_SOURCE, 'user_id'],
  })

  const [hasReadForUsers] = useResourcePermissions(usersConfig.name, SYSTEM_PERMISSION_READ)
  const { data: userData, isLoading } = useGetOne(
    usersConfig.name,
    { id: userId },
    { enabled: hasReadForUsers && Boolean(userId) },
  )

  useEffect(() => {
    setIsFormReady(true)
  }, [])

  useEffect(() => {
    if (isFormReady) {
      // Handle the case (and stop) where a booking that has been made free is updated
      if (
        formType === UPDATE &&
        userData &&
        record[BOOKING_BILLING_TYPE_SOURCE] === BOOKING_BILLING_TYPE_FREE &&
        hasOnlyBillingType(
          userData[BILLING_TYPES_OVERRIDE_SOURCE] || accountBillingSettings[ACCOUNT_BILLING_TYPES_SOURCE],
          BOOKING_BILLING_TYPE_PAID,
        )
      ) {
        setValue(BOOKING_BILLING_SETTINGS_SOURCE, {
          [ACCOUNT_BILLING_TYPES_SOURCE]: [BOOKING_BILLING_TYPE_FREE],
          [ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE]: BOOKING_BILLING_TYPE_FREE,
        })
        return
      }
      // Handle other cases
      if (!Boolean(userId)) {
        setValue(BOOKING_BILLING_SETTINGS_SOURCE, accountBillingSettings)
      } else if (userData && !isLoading) {
        const overrides = {}
        ACCOUNT_BILLING_SETTINGS_SOURCES.forEach((src) => {
          const overrideValue = userData[`${src}_override`]
          if (overrideValue !== null) {
            overrides[src] = overrideValue
          }
        })
        setValue(BOOKING_BILLING_SETTINGS_SOURCE, {
          ...accountBillingSettings,
          ...overrides,
          ...(record && { [ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE]: record[BOOKING_BILLING_TYPE_SOURCE] }),
        })
      }
    }
  }, [isFormReady, userId, setValue, isLoading]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isFormReady && !billingSettings[ACCOUNT_BILLING_TYPES_SOURCE].includes(billingType)) {
      setValue(BOOKING_BILLING_TYPE_SOURCE, billingSettings[ACCOUNT_DEFAULT_BILLING_TYPE_SOURCE])
    }
  }, [isFormReady, JSON.stringify(billingSettings)]) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

const VoucherFormSection = ({ formType }) => {
  const { setValue } = useFormContext()
  const isVoucherRestricted = useInputDenyPermission(vouchersConfig.options.referenceKey, formType)
  const [hasReadForVouchers] = useResourcePermissions(vouchersConfig.name, SYSTEM_PERMISSION_READ)
  const values = useWatch()
  const hasVoucher = values.has_voucher
  const makeBookingFree = values[BOOKING_MAKE_BOOKING_FREE_SOURCE]
  const readOnly = isVoucherRestricted || makeBookingFree

  const onToggle = (event) => {
    setValue('has_voucher', event.target.checked)
    if (!event.target.checked) {
      setValue(vouchersConfig.options.referenceKey, null)
    }
  }

  useEffect(() => {
    if (formType === CREATE && makeBookingFree) {
      setValue('has_voucher', false)
      setValue(vouchersConfig.options.referenceKey, null)
    }
  }, [setValue, formType, makeBookingFree])

  return hasReadForVouchers && isBookingPaid(values) ? (
    <>
      <FormDivider />
      <AdvancedBooleanInput
        source="has_voucher"
        helperText={hasVoucher ? false : 'resources.bookings.forms.helperTexts.voucher_id'}
        readOnly={readOnly}
        onChange={onToggle}
        defaultValue={Boolean(values.voucher_id)}
        sx={{ width: 330 }}
      />
      {hasVoucher && <VoucherReferenceInput label="resources.vouchers.fields.code" readOnly={readOnly} />}
    </>
  ) : null
}

const AdditionalDriversArrayInput = () => {
  const translate = useTranslate()
  const [userId, additionalDriverUserIds] = useWatch({ name: ['user_id', 'additional_driver_user_ids'] })

  const maxAmountOfDrivers = 19
  const excludedDriverUserIds = additionalDriverUserIds?.map((element) => element?.user_id) || []
  const excludedUserIds = [userId, ...excludedDriverUserIds].filter(Boolean)

  return (
    <ArrayInput source="additional_driver_user_ids" helperText={false}>
      <SimpleFormIterator
        disableReordering
        addButton={<SimpleFormIteratorAddButton label="resources.bookings.forms.giveAccessToAnAdditionalUser" />}
        disableAdd={additionalDriverUserIds?.length >= maxAmountOfDrivers}
        TransitionProps={{ enter: false, exit: false }}
      >
        <UserReferenceInput excludedIds={excludedUserIds} />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

const BookingFormLayout = ({ defaultValues, disabledInputsSources = [], type = UPDATE, ...props }) => {
  const bookingsJustifications = useGetBookingsJustifications()
  const account = useGetCurrentAccount()
  const { filterValues } = useListContext()

  const record = useRecordContext(props)
  const transformedRecord = useMemo(() => {
    if (type === UPDATE) {
      return {
        ...record,
        additional_driver_user_ids: record.additional_driver_user_ids
          ? record.additional_driver_user_ids.map((id) => ({ user_id: id }))
          : [],
        has_voucher: Boolean(record.voucher_id),
      }
    }
    return record
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const isSmallScreen = useSmallScreen()
  const commonClasses = useCommonStyles()

  const now = useRef(getRoundedNow())
  const end = useRef(getDateWithThirtyMinutesOffset())

  const accountBillingSettings = account
    ? ACCOUNT_BILLING_SETTINGS_SOURCES.reduce((acc, src) => {
        acc[src] = account[src]
        return acc
      }, {})
    : {}

  defaultValues = {
    start_scheduled_on: now.current,
    end_scheduled_on: end.current,
    [BOOKING_BILLING_SETTINGS_SOURCE]: accountBillingSettings,
    ...filterValues,
    ...(filterValues?.organisation_id && filterValues?.hub_id ? {} : { vehicle_id: undefined }),
    ...defaultValues,
  }

  return Boolean(account) && Boolean(bookingsJustifications) ? (
    <AdvancedSimpleForm
      record={transformedRecord}
      toolbar={<BasicFormToolbar formType={type} />}
      defaultValues={defaultValues}
      type={type}
    >
      <OrganisationReferenceInput
        readOnly={type === UPDATE || isDisabled(disabledInputsSources, organisationsConfig.options.referenceKey)}
      />
      <HubReferenceInput
        readOnly={type === UPDATE || isDisabled(disabledInputsSources, hubsConfig.options.referenceKey)}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <VehicleReferenceInput
            readOnly={
              (type === UPDATE && formData.era !== BOOKING_ERA_UPCOMING) ||
              isDisabled(disabledInputsSources, vehiclesConfig.options.referenceKey)
            }
          />
        )}
      </FormDataConsumer>
      <UserReferenceInput
        readOnly={type === UPDATE || isDisabled(disabledInputsSources, usersConfig.options.referenceKey)}
      />
      <FormDivider />
      <FormDataConsumer>
        {({ formData }) => (
          <>
            <AdvancedDateTimeInput
              validate={required()}
              source="start_scheduled_on"
              minDate={now.current}
              readOnly={type === UPDATE && formData.era !== BOOKING_ERA_UPCOMING}
            />
            <AdvancedDateTimeInput
              validate={[
                required(),
                (value) => validateEndDateAfterStartDate(formData.start_scheduled_on, value, 30, 1),
              ]}
              source="end_scheduled_on"
              minDate={now.current}
            />
          </>
        )}
      </FormDataConsumer>
      <FormDivider />
      <BillingSettingsInjector
        accountBillingSettings={accountBillingSettings}
        formType={type}
        record={transformedRecord}
      />
      <FormDataConsumer>
        {({ formData }) => (
          <RadioButtonGroupInput
            source={BOOKING_BILLING_TYPE_SOURCE}
            validate={required()}
            choices={getBillingTypeChoices(formData[BOOKING_BILLING_SETTINGS_SOURCE][ACCOUNT_BILLING_TYPES_SOURCE])}
            style={{ width: COMMON_INPUT_WIDTH }}
            helperText={false}
          />
        )}
      </FormDataConsumer>
      <BookingJustificationInput source="justification" bookingsJustifications={bookingsJustifications} />
      <FormDataConsumer>
        {({ formData }) =>
          type === CREATE &&
          hasOnlyBillingType(
            formData[BOOKING_BILLING_SETTINGS_SOURCE][ACCOUNT_BILLING_TYPES_SOURCE],
            BOOKING_BILLING_TYPE_PAID,
          ) && (
            <>
              <FormDivider />
              <AdvancedBooleanInput
                source={BOOKING_MAKE_BOOKING_FREE_SOURCE}
                className={isSmallScreen ? null : commonClasses.doubleInput}
                defaultValue={false}
              />
            </>
          )
        }
      </FormDataConsumer>
      <VoucherFormSection formType={type} />
      <FormDivider />
      <AdditionalDriversArrayInput />
    </AdvancedSimpleForm>
  ) : null
}

export const useCreateBooking = ({ disabledInputsSources }) =>
  useCreateResource('bookings', <BookingFormLayout />, transformValues, disabledInputsSources)

export const useEditBooking = () => useEditResource('bookings', <BookingFormLayout />, transformValues)

export default BookingFormLayout
