import { Typography } from '@mui/material'
import { CreateDialog, CreateInDialogButton } from '@react-admin/ra-form-layout'
import { useTranslate } from 'ra-core'
import { cloneElement, useState } from 'react'
import {
  CREATE,
  Create,
  CreateActions,
  CreateButton,
  ResourceContextProvider,
  useCreatePath,
  useNotify,
  useResourceContext,
} from 'react-admin'
import { useLocation } from 'react-router-dom'

import { getResourceSuccessMessage } from '.'
import { useHandleRedirection } from './hooks'
import { Title } from './show'
import { SYSTEM_PERMISSION_CREATE } from '../../config/permissions'
import { useResourcePermissions } from '../../domain/permissions'
import { useSmallScreen } from '../../utils/theme'

export default ({ titleComponent, formLayoutComponent, transformValues }) => {
  const handleRedirection = useHandleRedirection()
  const location = useLocation()
  const notify = useNotify()
  const resource = useResourceContext()
  const translate = useTranslate()

  const onSuccess = async (record) => {
    await handleRedirection(record)
    await notify(getResourceSuccessMessage(resource, 'forms', 'create'))
  }
  const onError = (error) => notify(error.message, { type: 'error' })

  const params = new URLSearchParams(location.search)
  const defaultValues = JSON.parse(params.get('defaultValues'))
  const createFormLayoutComponent = cloneElement(formLayoutComponent, { defaultValues, type: CREATE })
  const createTitleComponent = cloneElement(titleComponent ?? Title, {
    details: translate(`resources.${resource}.forms.create.pageTitle`),
  })

  return (
    <Create
      title={createTitleComponent}
      actions={<CreateActions />}
      mutationOptions={{ onSuccess, onError }}
      transform={transformValues}
    >
      {createFormLayoutComponent}
    </Create>
  )
}

const AdvancedCreateInDialogButton = ({ children: formLayout, defaultValues, sx, title, ...props }) => {
  const translate = useTranslate()
  const resource = useResourceContext(props)
  title = title || `resources.${resource}.forms.create.title`

  const notify = useNotify()
  const onSuccess = () => notify(getResourceSuccessMessage(resource, 'forms', 'create'))
  const onError = (error) => notify(error.message, { type: 'error' })

  return (
    <CreateInDialogButton
      mutationMode="pessimistic"
      mutationOptions={{ onSuccess, onError }}
      sx={{ '& .MuiDialogTitle-root': { borderBottom: '1px solid', borderColor: 'divider' }, ...sx }}
      title={<Typography variant="h6">{translate(title)}</Typography>}
      {...props}
    >
      {cloneElement(formLayout, { defaultValues, type: CREATE })}
    </CreateInDialogButton>
  )
}

export const AdvancedCreateButton = ({
  children: formLayout,
  defaultValues = {},
  isFloatingOnSmallScreen = true,
  sx,
  title,
  ...props
}) => {
  const createPath = useCreatePath()
  const resource = useResourceContext(props)
  const [hasCreate] = useResourcePermissions(resource, SYSTEM_PERMISSION_CREATE)
  const isSmallScreen = useSmallScreen()

  if (!hasCreate) {
    return null
  }

  const createButtonFloatingSx = isFloatingOnSmallScreen
    ? { bottom: '70px' }
    : {
        position: 'relative',
        right: 0,
        bottom: 0,
        marginBottom: '3px',
        color: 'primary.main',
        backgroundColor: 'transparent',
        boxShadow: 'none',
      }

  return isSmallScreen ? (
    <CreateButton
      sx={{ '&.RaCreateButton-floating': createButtonFloatingSx }}
      to={{
        pathname: createPath({ resource, type: 'create' }),
        search: '?' + new URLSearchParams({ defaultValues: JSON.stringify(defaultValues) }),
      }}
    />
  ) : (
    <AdvancedCreateInDialogButton defaultValues={defaultValues} sx={sx} title={title} {...props}>
      {formLayout}
    </AdvancedCreateInDialogButton>
  )
}

export const useCreateResource = (resource, formLayout, transformValues, disabledInputsSources) => {
  const [createPopupState, setCreatePopupState] = useState({ isOpen: false, values: {} })
  const handleCreatePopupOpen = (values) => setCreatePopupState({ isOpen: true, values })
  const handleCreatePopupClose = () => setCreatePopupState({ isOpen: false, values: {} })

  const notify = useNotify()
  const onSuccess = () => notify(getResourceSuccessMessage(resource, 'forms', 'create'))
  const onError = (error) => notify(error.message, { type: 'error' })

  const dialog = (
    <ResourceContextProvider value={resource}>
      <CreateDialog
        close={handleCreatePopupClose}
        isOpen={createPopupState.isOpen}
        mutationMode="pessimistic"
        mutationOptions={{ onSuccess, onError }}
        open={handleCreatePopupOpen}
        sx={{ '& .MuiDialogTitle-root': { borderBottom: '1px solid', borderColor: 'divider' } }}
        title={`resources.${resource}.forms.create.title`}
        transform={transformValues}
      >
        {cloneElement(formLayout, { defaultValues: createPopupState.values, disabledInputsSources, type: CREATE })}
      </CreateDialog>
    </ResourceContextProvider>
  )

  return [handleCreatePopupOpen, dialog]
}
